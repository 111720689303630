<template>
  <div class="video-container">
    <!-- banner -->
    <div class="banner">
      <div class="banner-container">
        <video src="https://qkodo.playlistmusic.com.cn/default%2Fvideo-banner20220929.mp4" autoplay muted loop
          poster="https://qkodo.playlistmusic.com.cn/assets/common/202201/banner-2022-01-07.jpg" preload></video>
      </div>
      <div class="crumb-wapper container_media">
        <vf-breadcrumb :data="breadData" type="banner"></vf-breadcrumb>
      </div>
      <div class="search">
        <h3>现在开始，为您的项目找到完美的素材</h3>
        <!-- 搜索框 -->
        <Search :showIdent="false" mode="label_page" class="search-input" style="margin: 0 auto" placeholder="输入视频/标签名称"
          @search="handleSearch" />
        <!-- 热搜标签 -->
        <div class="hot-tag-box">
          <Tag class="hot-tag-box-item" v-for="(item, index) in hotSearch" :key="index" mode="text"
            @onTag="handleHotTagClick(item)">&nbsp;&nbsp;{{ item.name }}&nbsp;&nbsp;</Tag>
        </div>
      </div>
    </div>
    <div class="content">
      <!-- 标签 -->
      <div class="tag-container">
        <div class="tag-wrap container_media">
          <div class="tag-item">
            <div class="tag-item-title">分类：</div>
            <div class="tag-item-content">
              <span class="tag-item-content-text f-5" :class="{ 'active': firstActiveKey == -1 }" @click="chooseFirstTag({label_id: -1})">全部</span>
              <span class="tag-item-content-text f-5" :class="{ 'active': firstActiveKey == tag.label_id }" v-for="tag in tagList"
                :key="tag.label_id" @click="chooseFirstTag(tag)">{{ tag.label_name }}</span>
            </div>
          </div>
          <!-- 二级标签 -->
          <div class="tag-item" v-if="firstActiveKey != -1">
            <div class="tag-item-title">场景：</div>
            <div class="tag-item-content">
              <!-- <span class="tag-item-content-text" :class="{ 'active': !secondActiveKey.label_id }"
                @click="chooseSecondTag(null)">全部</span> -->
              <span class="tag-item-content-text" :class="{ 'active': chooseTagList.includes(sonTag) || secondActiveKey.label_id == sonTag.label_id }"
                v-for="sonTag in firstActiveTag.son" :key="sonTag.label_id" @click="chooseSecondTag(sonTag)">{{ sonTag.name }}</span>
            </div>
          </div>
          <div class="third-label" v-if="thirdTagsList.length">
            <div class="tags-wrap">
              <span v-for="tTag in thirdTagsList" :key="`tTag${tTag.label_id}`" @click="chooseThirdTag(tTag)" :class="{'t_active_label': chooseTagList.includes(tTag)}">{{tTag.label_name}}</span>
            </div>
          </div>
          <div class="tag-sort-box">
            <div class="tag_sort">
              <div class="tag_sort_title">排序：</div>
              <div class="tag_sort_content">
                <span class="tag_sort_text" :class="{ 'active': item.value == orderNo }" v-for="(item, index) in orderList"
                  :key="index" @click="setOrder(item)">{{ item.label }}</span>
              </div>
            </div>
            <div class="tag-pagina">
              <i class="pagina_l el-icon-arrow-left" :class="{ 'active': lpage }" @click="prevPage"></i>
              <span class="pager">
                <i class="page">{{ pager.page }}</i>
                <i class="pagesize">/...</i>
              </span>
              <i class="pagina_r el-icon-arrow-right" :class="{ 'active': rpage }" @click="nextPage"></i>
            </div>
          </div>
        </div>
      </div>
      <!-- 标签选中结果 -->
      <div v-if="chooseTagList.length" class="selected-tag-box container_media">
        <span class="title"><i>视频列表</i><em>|</em> 已选标签：</span>
        <el-tag v-for="item in chooseTagList" :key="`check_${item.label_id}`" size="small" closable
          @close="handleCloseTag(item)">{{ item.label_name }}</el-tag>
        <span class="clean" @click="cleanTagSearch">全部清除</span>
      </div>
      <div class="main" v-loading="loading">
        <div class="video-cards container_media">
          <div class="video-card" v-for="data in list" :key="data.object_id">
            <div class="video-items">
              <VideoCard :video="data || {}" @download="handleDownload(data)" @hover="handleHover"/>
              <div class="title-info">
                <p class="name" :class="{'hober-v': data.object_id == hoverVideoId}">{{ data.name }}</p>
                <img v-if="data.is_show_hot === 1" :src="require(`@/assets/svg/${ orderNo == 2 && data.is_show_hot === 1 ? 'hot-badge.svg' : 'new-icon.svg'}`)" />
              </div>
            </div>
          </div>
        </div>
        <Pagination :hiddenPage="true" :total="pager.total" :limit.sync="pager.limit" :page.sync="pager.page"
          @pagination="handlePageChange" />
      </div>
    </div>
  </div>
</template>

<script>
import { fetchVideoTags, fetchVideo, fetchVideoHotSearch } from '@/api/video'
import Search from '@/components/Search'
import Tag from '@/components/Tag'
import VideoCard from '@/views/limitExempt/components/videoCard.vue'
import Pagination from '@/components/Pagination'
import Buttons from '@/layout/mixins/Buttons'
import { send_eventApi } from '@/utils/common.js'
import { mapGetters } from 'vuex'
import { orderList } from '@/config'
import EventBus from '@/utils/bus.js'
import VfBreadcrumb from '@/components/VfBreadcrumb.vue'

export default {
  name: 'Video',
  components: { Search, Tag, Pagination, VideoCard, VfBreadcrumb },
  data() {
    return {
      breadData: [
        {breadcrumbName: 'PlayList', path: '/'},
        {breadcrumbName: '视频'}
      ],
      loading: false,
      hoverVideoId: '',
      tagList: {},
      firstActiveTag: {}, // 被选择的一级标签对象 firstActiveTag.son为被选中的二级列表
      firstActiveKey: -1, // 标签列表一级选中下标 默认显示全部
      secondActiveKey: {}, // 包含三级标签的二级标签被选中
      chooseTagList: [], // 被选中的二级标签
      thirdTagsList: [], // 被选中的三级列表 待补全逻辑
      orderList,
      orderNo: '',
      hotSearch: [], // 热搜词集合
      list: [],
      pager: {
        // 分页器相关
        total: 0, // 总条数
        page: 1, // 页码
        limit: 20 // 每页展示条数
      },
      lpage: false,
      rpage: false
    }
  },
  // 如果从视频详情返回的 则不清空已选标签 否则清空
  beforeRouteEnter(to, from, next) {
    // console.log(from)
    if (from.path == '/video/detail') {
      next()
    } else {
      next(vm => {
        vm.pager.page = 1;
        vm.cleanTagSearch();
        vm.getVideoList()

      })
    }
  },
  computed: {
    ...mapGetters({
      userInfo: 'userInfo'
    }),
    pages() {
      return Math.ceil(this.pager.total / this.pager.limit);
    },
  },
  created() {
    this.getTags()
    this.getHot()
  },
  mounted(){
    EventBus.$on("collect", (data) => {
      const index = this.list.findIndex((i) => i.object_id === data.object_id)
      if(index > -1) {
        this.list[index].is_collect = data.is_collect
        this.list[index].is_collected = data.is_collected
      }
    });
  },
  mixins: [Buttons],
  methods: {
    // 鼠标放上字体变红
    handleHover(id){
      this.hoverVideoId = id
    },
    /**
     * 获取视频热搜词
     */
    async getHot() {
      try {
        const params = {
          type: 4
        }
        const { data } = await fetchVideoHotSearch(params);
        this.hotSearch = data
      } catch (e) {
        console.log(e)
      }
    },
    /**
     * 点击热搜标签
     */
    handleHotTagClick(item) {
      const obj = {
        label_name: item.name,
        user_name: this.userInfo ? this.userInfo.name : '',
        user_id: this.userInfo ? this.userInfo.user_id : ''
      }
      send_eventApi('e_slt', obj)
      this.$router.push({
        path: '/search-result',
        query: {
          type: 4,
          keyword_name: item.name
        }
      })
    },
    /**
     * 获取视频标签
     */
    async getTags() {
      try {
        const { data } = await fetchVideoTags()
        // console.log(data)
        if (data?.length) {
          this.tagList = data
        }
      } catch (e) {
        console.log(e)
      }
    },
    chooseFirstTag(tag){
      if(this.firstActiveTag == tag && tag.label_id == -1) return
      // 再次点击除全部外的一级标签，取消选中状态
      if(this.firstActiveTag == tag) {
        this.firstActiveTag = {label_id: -1}
        this.firstActiveKey = -1
      }else {
        this.firstActiveTag = tag
        this.firstActiveKey = tag.label_id
      }
      if(tag.label_id == -1){
        this.chooseTagList = []
        this.pager.page = 1
        this.getVideoList()
      }
      this.thirdTagsList = []
      this.secondActiveKey = {}
    },

    chooseSecondTag(tag){
      // 若有三级标签，展示三级标签选项，否则记录所选标签
      if(tag.son){
        if(tag == this.secondActiveKey){
          this.thirdTagsList = []
          this.secondActiveKey = {}
        }else {
          this.thirdTagsList = tag.son
          this.secondActiveKey = tag
        }
      }else{
        if(this.chooseTagList.includes(tag)){
          const index = this.chooseTagList.findIndex((i) => i.label_id === tag.label_id)
          this.chooseTagList.splice(index, 1)
        }else {
          this.chooseTagList.push(tag)
          this.sendApi(tag.label_name)
        }
        this.thirdTagsList = []
        this.secondActiveKey = {}
        this.pager.page = 1
        this.getVideoList()
      }
    },
    chooseThirdTag(tag){
      if(this.chooseTagList.includes(tag)) {
        const index = this.chooseTagList.findIndex((i) => i.label_id === tag.label_id)
        this.chooseTagList.splice(index, 1)
      }else{
        this.chooseTagList.push(tag)
        this.sendApi(tag.label_name)
      }
      this.pager.page = 1
      this.getVideoList()
    },
    setOrder(item) {
      this.orderNo = item.value;
      this.pager.page = 1;
      this.getVideoList()
    },
    prevPage() {
      if (this.pager.page < 2) return;
      this.pager.page--;
      this.getVideoList()
      this.lpage = true;
      this.rpage = false;
    },
    nextPage() {
      if (this.pager.page < this.pages) {
        this.pager.page++;
        this.getVideoList();
        this.lpage = false;
        this.rpage = true;
      }
    },
    // 清除单个标签
    handleCloseTag(item) {
      const index = this.chooseTagList.findIndex((i) => i.label_id === item.label_id)
      this.chooseTagList.splice(index, 1)
      this.pager.page = 1;
      this.getVideoList()
    },
    // 全部清除标签
    cleanTagSearch() {
      this.chooseTagList = []
      this.thirdTagsList = []
      this.secondActiveKey = {}
      this.firstActiveKey = -1
      this.pager.page = 1;
      this.getVideoList()
    },
    /**
     * 获取视频列表
     * @param {Array} tagId 按标签id搜索
     * @param {Number} page 页码
     */
    async getVideoList() {
      this.loading = true
      // console.log(tag)
      try {
        const { data } = await fetchVideo({
          page: this.pager.page,
          limit: this.pager.limit,
          label_id: this.chooseTagList.map(i=>i.label_id).join(','),
          order: this.orderNo
        })
        this.loading = false
        this.list = data.list.filter((i) => {
          return {
            ...i,
            is_collected: i.is_collect
          }
        })
        this.pager.total = data.total
      } catch (e) {
      this.loading = false
        console.log(e)
      }
    },
    /**
     *
     * 发送埋点事件
     */
    sendApi(label_name) {
      const obj = {
        label_name: label_name,
        user_name: this.userInfo ? this.userInfo.name : '',
        user_id: this.userInfo ? this.userInfo.user_id : ''
      }
      send_eventApi('e_slt', obj)
    },

    /**
     * 前往视频详情页
     * @param {String} videoId 视频id
     */
    async gotoDetail(videoId) {
      // 添加限制播放次数拦截
      const result = await this.handleCheckedTimes(4, videoId)

      if (!result) return
      this.$router.push(`/video/detail?videoId=${videoId}`)
    },
    /**
     * 切换页码
     * @param e.page 页码
     */
    handlePageChange(e) {
      this.pager.page = e.page
      window.scroll({ top: 500, left: 0, behavior: 'smooth' });
      this.getVideoList()
    }
  }
}
</script>

<style lang="scss" scoped>
.f-5 {
  font-weight: 500;
}
 /deep/.el-pagination {
    .btn-quicknext + .number  {
      display: none;
    }
}
/deep/.el-select-dropdown {
  .el-scrollbar {
    .el-select-dropdown__item {
      span {
        padding-left: 15px !important;
      }
    }
  }
}

.video-container {
  min-height: 100vh;

  .banner {
    width: 100%;
    position: relative;

    .banner-container {
      width: 100%;
      // height: 520px;
      video {
        width: 100%;
        // height: 100%;
        // object-fit: fill;
      }
    }

    img {
      width: 100%;
    }
    .crumb-wapper {
      width: 100%;
      position: absolute;
      top: 84px;
      left: 50%;
      transform: translateX(-50%);
      z-index: 3;
    }
    .search {
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      bottom: 11.7vw;

      h3 {
        text-align: center;
        font-size: 46px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #FFFFFF;
        white-space: nowrap;
        line-height: 65px;
        text-shadow: 0px 0px 8px rgba(0, 0, 0, 0.48);
      }

      /deep/.search-input {
        .input {
          border: none;
          background: rgba(255, 255, 255, 0.8);

          input::-webkit-input-placeholder {
            color: rgba(0, 0, 0, 0.5);
          }

          input::-moz-input-placeholder {
            color: rgba(0, 0, 0, 0.5);
          }

          input::-ms-input-placeholder {
            color: rgba(0, 0, 0, 0.5);
          }
        }
      }
    }

    .hot-tag-box {
      // @include center-middle-flex;
      width: 637px;
      overflow: hidden; // 溢出隐藏
      display: -webkit-box; //作为弹性盒伸缩模型
      -webkit-box-orient: vertical; // 从上到下垂直排列
      -webkit-line-clamp: 2; //  显示的行数
      margin: 12px auto 0px;
      line-height: 20px;

      &-item {
        @include font-normal($size: 14px, $color: rgba(255, 255, 255, 0.8));
      }

      &-item:hover {
        color: $theme-color;
      }
    }
  }

  /deep/ {
    .search-container {
      .input-text {
        color: #ffffff;

        .el-input__inner {
          color: #ffffff;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 500;
          padding-left: 24px;
        }

        .el-input__inner::placeholder {
          color: rgba(255, 255, 255, 0.6);
        }
      }
    }
  }

  .content {

    // margin: 0 120px;
    .tags-container {
      width: calc(100% - 240px);
      margin: 26px auto 0;

      /deep/ {
        .el-tabs__item.is-active {
          color: rgba(51, 51, 51, 0.8);
        }

        .el-tabs__item {
          padding: 0 24px !important;
          height: 60px;
          line-height: 54px;
          color: rgba(51, 51, 51, 0.6);
          @include font-bold($size: 18px, $color: rgba(51, 51, 51, 0.48));
        }

        .el-tabs__active-bar {
          height: 4px;
        }

        .el-tabs__nav-wrap::after {
          bottom: 1px;
        }
      }

      .sub-tags-box {
        display: flex;
        flex-wrap: wrap;
        padding: 10px 0px;

        &-item {
          @include font-normal($size: 14px, $color: #333);
          line-height: 20px;
          padding: 3px 15px 3px 24px;
        }

        &-item.active {
          background: rgba($theme-color, 0.1);
          border-radius: 16px;
        }
      }

      .sub-tags-third {
        width: 100%;
        height: auto;
        padding: 5px 0px;
        background: #F5F6FA;
        border-radius: 3px;
      }

      .selected-tag-box {
        padding-top: 10px;

        .title {
          @include font-normal($size: 14px, $color: rgba(51, 51, 51, 0.8));
          margin-left: 11px;
        }

        .el-tag {
          border-radius: 20px;
          margin-left: 12px;
          font-size: 14px;
          cursor: pointer;
          padding: 0 15px;
          border-color: transparent;

          /deep/ .el-tag__close {
            font-size: 14px;
            font-weight: bold;
            right: -6px;
          }

          /deep/ .el-tag__close:hover {
            background: none;
            color: $theme-color;
          }
        }

        .clean {
          @include font-normal($size: 12px, $color: #4d4d4f);
          line-height: 17px;
          margin-left: 12px;
          cursor: pointer;
        }
      }
    }
    .tag-container {
      width: 100%;
      background: #fff;
      box-shadow: 0px 5px 9px 0px rgba(0,0,0,0.1);
      user-select: none;
      font-size: 14px;
      color: #666;
      .third-label {
        padding: 10px 20px 4px;
        background: #FAFAFA;
        border-radius: 4px;
        margin: 4px 0 20px 30px;
        .tags-wrap {
          span {
            cursor: pointer;
            height: 24px;
            line-height: 24px;
            padding: 0 10px;
            border-radius: 4px;
            margin-right: 10px;
            margin-bottom: 6px;
            background: transparent;
          }
        }
      }
      .t_active_label {
        color: #E52A0D!important;
      }
      .tag-wrap {
        // max-width: 1200px;
        // margin: 0 auto;
        padding-top: 22px;
        box-sizing: border-box;
      }

      .tag-item:nth-last-of-type(1) {
        margin-bottom: 0;
      }

      .tag-item {
        @include center-middle-flex($justify: flex-start);
        padding-bottom: 14px;

        &:last-child {
          border-bottom: 1px dashed rgba(0, 0, 0, 0.1);
        }

        &-title {
          width: 46px;
          //@include font-bold($size: 18px, $color: #333);
          //line-height: 25px;
          //font-family: PingFangSC-Medium, PingFang SC;
          //font-weight: 500;
          margin-right: 4px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
        }

        &-content {
          overflow: hidden;

          &-text {
            height: 24px;
            margin-right: 10px;
            line-height: 24px;
            cursor: pointer;
            padding: 0 10px;
            border-radius: 4px;
          }
          &-text:hover {
            background: #FDF4F3;
          }
          &-text.active {
            background: #FDF4F3!important;
            color: #E52A0D;
          }
        }
      }

      .tag-sort-box {
        width: 100%;
        height: 60px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-top: 1px dashed rgba(0, 0, 0, 0.1);
      }

      .tag_sort {
        flex: 1;
        @include center-middle-flex($justify: flex-start);
        //padding-top: 20px;
        // border-top: 1px dashed rgba(0, 0, 0, 0.1);

        &_title {
          width: 46px;
          margin-right: 12px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
        }

        .tag_sort_text {
          cursor: pointer;
          height: 24px;
          line-height: 24px;
          margin-right: 10px;
          padding: 0 10px;
          border-radius: 4px;
        }

        .tag_sort_text.active {
          color: #E52A0D;
          background: #FDF4F3;
        }
      }

      .tag-pagina {
        @include center-middle-flex($justify: flex-start);
        // border-top: 1px dashed rgba(0, 0, 0, 0.1);
        display: flex;
        align-items: center;

        i {
          font-style: normal;
          display: inline-block;
        }

        .pagina_l,
        .pagina_r {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 36px;
          height: 36px;
          border-radius: 4px;
          border: 1px solid rgba(0, 0, 0, 0.1);
          cursor: pointer;
        }

        .pager {
          margin: 0 15px;
          font-size: 14px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: rgba(0, 0, 0, 0.6);
          display: flex;
          align-items: center;
        }

        .page {
          color: #E52A0D;
        }

        .active {
          border-color: #E52A0D;
          color: #E52A0D;
        }
      }
    }



    .main {
      @include center-middle-flex;
      flex-direction: column;
      padding: 0 0 80px;
      position: relative;
      .video-cards {
        margin: 36px 0;
        // display: grid;
        // grid-template-columns: repeat(4, 1fr);
        // grid-gap: 0px 24px;
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        .video-card{
          width: 23.5%;
          margin-right: 2%;
          // margin-bottom: 20px;
          cursor: pointer;
          &:nth-child(4n+4) {
            margin-right: 0;
          }
        }
        .video-items {
          @include size(100%, 80%);
          border-radius: 4px;
          margin-bottom: 20px;
          .title-info{
            @include center-middle-flex($justify: flex-start);
          }
          .name {
            font-size: 16px;
            color: rgba(0, 0, 0, 0.85);
            line-height: 22px;
            max-width: 90%;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            margin-top: 12px
          }
          img {
            @include size(28px, 20px);
            margin-left: 8px;
          }
          .hober-v {
            color: #E52A0D;
          }
          video {
            @include size(100%, 100%);
            object-fit: fill;
            position: absolute;
            top: 0;
            left: 0;
            z-index: 2;
            display: block;
          }

          .coverimg {
            @include size(100%, 100%);
            position: absolute;
            top: 0;
            left: 0;
            z-index: 2;
            display: block;
          }

          .loading {
            display: inline-block;
            font-size: 30px;
            position: absolute;
            top: 45%;
            left: 45%;
            color: #fff;
          }
        }
      }
    }
  }
  .selected-tag-box {
    padding-top: 36px;
    padding-bottom: 10px;
    border-bottom: 1px solid rgba(0, 0, 0, .05);
    margin-bottom: 36px;

    .title {
      @include font-normal($size: 14px, $color: rgba(0, 0, 0, .5));
      i {
        font-style: normal;
        font-size: 18px;
        color: rgba(0, 0, 0, .75);
        font-weight: 600;
      }
      em{
        font-style: normal;
        margin: 0 8px;
      }
    }

    .el-tag {
      border-radius: 12px;
      margin-left: 12px;
      font-size: 14px;
      cursor: pointer;
      padding: 0 12px;
      color: rgba(0, 0, 0, 0.85);
      background: #fff;
      border: 1px solid rgba(0,0,0,0.85);

      /deep/ .el-tag__close {
        font-size: 14px;
        right: -2px;
        color: rgba(0, 0, 0, 1);
      }

      /deep/ .el-tag__close:hover {
        background: none;
        color: rgba(0, 0, 0, 1);
      }
    }

    .clean {
      @include font-normal($size: 12px, $color: #4d4d4f);
      line-height: 17px;
      margin-left: 12px;
      cursor: pointer;
    }
  }
}
</style>
